<template>

<div class="info" v-if="hasInfo" :class="{'no-selection': !recommendation}">

	<div class="info-content">

		<h2 v-if="!recommendation">{{ content.info.title_alt }}</h2>

		<p v-if="!recommendation">{{ content.info.text_alt }}</p>

		<h2 v-if="recommendation">{{ content.info.title }}</h2>

		<p v-if="recommendation">{{ content.info.text }}</p>

		<div class="info-content-links">

			<router-link :to="{name: 'Recommendation.About'}" class="info-content-links-item is-about">

				<h3>{{ content.info.about.title }}</h3>

				<p>{{ content.info.about.text }}</p>

				<app-tick v-if="completed.about" />

			</router-link>

			<router-link :to="{name: 'Recommendation.Compare'}" class="info-content-links-item is-compare">

				<h3>{{ content.info.compare.title }}</h3>

				<p>{{ content.info.compare.text }}</p>

				<app-tick v-if="completed.compare" />

			</router-link>

			<router-link :to="{name: 'Recommendation.Survey'}" class="info-content-links-item is-survey">

				<h3>{{ content.info.survey.title }}</h3>

				<p>{{ content.info.survey.text }}</p>

				<app-tick v-if="completed.survey" />

			</router-link>

		</div>

	</div>
	
</div>

</template>

<script>

export default {

	computed: {

		content: function() {

			return this.$store.getters['data'].recommendations

		},

		completed: function() {

			return this.$store.getters['completed']

		},

		recommendation: function() {

			return this.$store.getters['recommendation']

		},

		hasInfo: function() {

			return this.$store.getters['recommendation'].info || !this.$store.getters['selection']

		}

	}

}

</script>

<style scoped>

.info {
	margin-top: 80px;
	background-color: #F0F0F0;
}

.info.no-selection {
	margin-top: 40px;
	background-color: #fff;
}

.is-mobile .info {
	margin-top: 20px;
	background-color: #fff;
}

.is-mobile .info.no-selection {
	margin-top: 60px;
}

.info-content {
	width: 1020px;
	margin: 0px auto;
	padding: 60px 0px;
}

.is-mobile .info-content {
	width: auto;
	padding: 0px 10px 20px 10px;
}

.info-content h2 {
	color: #707070;
	font-size: 36px;
	letter-spacing: 3.6px;
	font-weight: 300;
	text-transform: uppercase;
	text-align: center;
	margin-bottom: 20px;
}

.is-mobile .info-content h2 {
	font-size: 14px;
	letter-spacing: 2px;
}

.info-content p {
	color: #343434;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
}

.info-content-links {
	display: flex;
	padding: 60px 0px;
}

.is-mobile .info-content-links {
	padding: 10px 0px;
	flex-direction: column;
}	

.info-content-links-item {
	width: 33.3%;
	padding: 130px 20px 0px 20px;
	background-position: 50% 0px;
	background-repeat: no-repeat;
}

.is-mobile .info-content-links-item {
	background-size: auto 32px;
	min-height: 48px;
	background-position: 0px 50%;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 20px 0px;
	border-bottom: 1px solid #eee;
}

.is-mobile .info-content-links-item:last-child {
	border-bottom: 0px;
}

.info-content-links-item.is-about {
	background-image: url(~@/assets/recommendation.about.svg);
}

.info-content-links-item.is-compare {
	background-image: url(~@/assets/recommendation.compare.svg);
}

.info-content-links-item.is-survey {
	background-image: url(~@/assets/recommendation.survey.svg);
}

.info-content-links-item >>> .tick {
	right: 80px;
	top: 50px;
}

.is-mobile .info-content-links-item >>> .tick {
	left: 20px;
	top: 45px;
	width: 24px;
	height: 24px;
	background-size: 12px 12px;
}

.info-content-links-item h3 {
	color: #312D50;
	font-size: 24px;
	line-height: 29px;
	font-weight: 500;
	text-align: center;
	height: 58px;
	margin-bottom: 20px;
}

.is-mobile .info-content-links-item h3 {
	font-size: 18px;
	line-height: 22px;
	text-align: left;
	padding-left: 50px;
	height: auto;
	margin: 0px;
}

.info-content-links-item p {
	color: #343434;
	font-size: 18px;
	line-height: 22px;
	font-weight: 300;
	text-align: center;
}

.is-mobile .info-content-links-item p {
	display: none;
}	

</style>
